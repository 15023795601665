import React from "react";
import seminar1 from "../images/seminar_1.jpg";
import seminar2 from "../images/seminar_2.jpg";
import seminarroom1 from "../images/seminar_raum_1.jpg";
import seminarroom2 from "../images/seminar_raum_2.jpg";
import seminarroom3 from "../images/seminar_raum_3.jpg";
import seminarroom4 from "../images/seminar_raum_4.jpg";
import seminarroom5 from "../images/seminar_raum_5.jpg";
import seminarroom6 from "../images/seminar_raum_6.JPG";
import "../stylesheets/common.css";


//<p align="center">Das aktuelle Seminarprogramm finden Sie <a href="download/seminarprogram_de.pdf">hier</a> zum Download (Beinhaltet Anmeldeformular).</p>
export function seminarsGerman(){
    return(
        <div className={"col-lg-12 col-sm-12"}>
            <h2 align="center">Seminare</h2>
            <br />
            <p align="center"> In meinen Porzellanmalseminaren erlernen die Teilnehmer die Grundlagen des Zeichnens ebenso wie die speziellen Techniken beim Bemalen verschiedener Porzellangegenst&auml;nde. Schwerpunkt ist die Abbildung von Pflanzen und Tieren auf hochwertigem Wei&szlig;porzellan. Nach Absprache k&ouml;nnen die Teilnehmer die kunstvoll bemalten Objekte mit nach Hause nehmen.</p>
            <p align="center">Ich biete vier verschiedene Arten von Seminaren an: </p>
            <ul style={{"text-align":"center"}}>
                <li><a href="#seminar_1">Grundlagenseminare</a>, bei denen ich die Grundlagen des Malens als Vorbereitung zur Porzellanmalerei unterrichte,</li>
                <li><a href="#seminar_2">Themenseminare</a>, bei denen wir von mir vorgegebene Themen erarbeiten,</li>
                <li><a href="#seminar_3">Intensivseminare</a> in Bad Pyrmont, bei denen Sie die Themen selber festlegen k&ouml;nnen und</li>
                <li><a href="#seminar_4">Freie Seminare</a> bei Ihnen vor Ort, bei denen wir die individuell abgestimmten Themen erarbeiten.</li>
            </ul>

            <br />
            <hr />
            <br />
            <div id="seminar_1">
                <h2 align="center">Grundlagenseminare</h2>
                <p align="center">
                    Ich biete Grundlagensemniare an, in denen die Teilnehmer Schritt f&uuml;r Schritt die erforderlichen Techniken erlernen. Dabei erlernen wir zun&auml;chst die Grundlagen des Zeichnens.<br></br> In einem zweiten Schritt werden Naturstudien durchgef&uuml;hrt - ein Beispiel ist unten im Bild zu sehen - und die Aquarelltechnik wird erlernt.<br></br>

                    Um jedem eine intensive Betreuung zu erm&ouml;glichen, ist die Anzahl der Teilnehmer auf 6 beschr&auml;nkt.<br></br>

                    Die Seminare beginnen jeweils um 9:30 Uhr und enden um 17:00 Uhr.<br></br>

                    W&auml;hrend der Mittagspause (1 Stunde) organisiere ich gerne einen Mittagsimbiss.<br></br>

                    Die Arbeitsmittel wie Farben, Unterlagen und Pinsel m&uuml;ssen von den Teilnehmern mitgebracht werden. Bei der Beschaffung der Arbeitsmaterialien stehe ich vorab gerne beratend zur Seite.<br></br>

                    In Bad Pyrmont befindet sich ein umfangreiches Angebot an Hotels, Pensionen und Ferienwohnungen.<br></br> Auf Anfrage sende ich Ihnen gerne Informationen &uuml;ber die unterschiedlichen Unterk&uuml;nfte zu.
                </p>
                <p align="center"><img src={seminar1} alt={"Seminarinfo-Image"} /> </p>
            </div>
            <br />
            <hr />
            <br />
            <div id="seminar_2">
                <h2 align="center">Themenseminare</h2>
                <p align="center">F&uuml;r Fortgeschrittene f&uuml;hre ich mehrt&auml;gige Themenseminare durch. Dabei bemalen die Teilnehmer selbstst&auml;ndig Porzellanobjekte nach meinen Entw&uuml;rfen und Mustern.<br></br> Jeder Teilnehmer kann selbstverst&auml;ndlich seine individuelle Handschrift mit einbringen.

                    <br></br>Um jedem eine intensive Betreuung zu erm&ouml;glichen, ist die Anzahl der Teilnehmer auf 6 beschr&auml;nkt.

                    <br></br>Die Seminare beginnen jeweils um 9:30 Uhr und enden um 17:00 Uhr.<br></br>

                            W&auml;hrend der Mittagspause (1 Stunde) organisiere ich gerne einen Mittagsimbiss.

                    Die Teilnahmegeb&uuml;hr umfasst die Brennkosten.<br></br> Die Arbeitsmittel wie Farben und Pinsel m&uuml;ssen von den Teilnehmern mitgebracht werden. Bei der Beschaffung der Arbeitsmaterialien stehe ich vorab gerne beratend zur Seite.

                            In Bad Pyrmont befindet sich ein umfangreiches Angebot an Hotels, Pensionen und Ferienwohnungen. Auf Anfrage sende ich Ihnen gerne Informationen &uuml;ber die unterschiedlichen Unterk&uuml;nfte zu.
                </p>
                <p align="center"><img src={seminar2} alt={"SeminarInfo-Image"} /> </p>
            </div>
            <br />
            <hr />
            <br />
            <div id="seminar_3">
                <h2 align="center">Intensivseminare</h2>
                <p align="center">In mehreren Tagen bemalen die Teilnehmer selbstst&auml;ndig Porzellanobjekte mit individuell ausgew&auml;hlten Motiven.<br></br> Um eine intensive Betreuung und optimale Umsetzung der unterschiedlichen Motive zu erm&ouml;glichen, ist die Anzahl der Teilnehmer bei den Intensivseminaren auf 3 beschr&auml;nkt. Die Seminare finden in meinem eigenen Atelier in Bad Pyrmont statt.<br></br>

                    Die Seminare beginnen jeweils um 9:30 Uhr und enden um 17:00 Uhr.<br></br>

                    Die Teilnahmegeb&uuml;hr ist incl. Brennkosten.<br></br>

                    W&auml;hrend der Mittagspause, ca. 1,5 Std., organisiere ich einen Mittagsimbiss, welcher nicht in der Teilnahmegeb&uuml;hr enthalten ist.

                    Die Arbeitsmittel wie Unterlagen, Farben und Pinsel m&uuml;ssen von den Teilnehmern mitgebracht werden.<br></br> Bei der Beschaffung der Arbeitsmaterialien stehe ich vorab gerne beratend zur Seite.

                    Die Wahl der Themen erfolgt individuell nach vorheriger Absprache.

                    In Bad Pyrmont befindet sich ein umfangreiches Angebot an Hotels, Pensionen und Ferienwohnungen.<br></br> Auf Anfrage sende ich Ihnen gerne Informationen &uuml;ber die unterschiedlichen Unterk&uuml;nfte zu.
                </p>
            </div>
            <br />
            <hr />
            <br />
            <div id="seminar_4">
                <h2 align="center">Freie Seminare - International</h2>
                <p align="center">Falls Sie mit Ihrer Malgruppe ein Porzellanmalseminar in Ihrer N&auml;he besuchen m&ouml;chten, halte ich die Seminare gerne auch bei Ihnen vor Ort ab.<br></br> Die Inhalte k&ouml;nnen in diesem Fall frei festgelegt werden. Selbstverst&auml;ndlich werden wir in diesem Fall die Termine<br></br>, den Ablauf und die Dauer der Seminare individuell vereinbaren.<br></br>Die Kosten f&uuml;r ein solches "Freies Seminar" sind abh&auml;ngig von individuellen Randbedingungen wie Dauer, Inhalt und Ort.
                </p>
                <br />
            </div>
            <br />
            <hr />
            <br />
            <div id="seminar_5">
                <h2 align="center">Studio-Impressionen</h2>
                <p align="center">Hier finden Sie einige Impressionen aus meinem Atelier f&uuml;r Porzellan-/&Ouml;lmalerei in Bad Pyrmont / Weserbergland (Das romantische Land der M&auml;rchen, Schl&ouml;sser und Sagen). <br></br> Weitere Informationen zu Bad Pyrmont finden Sie <a target="_blank" href="http://bad-pyrmont.de">hier</a>.</p>
                <br />
                <div className={"row"}>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom1} alt={"Seminarroom"} /></p></div>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom2} alt={"Seminarroom"} /></p></div>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom3} alt={"Seminarroom"} /></p></div>
                </div>
                <br></br>
                <div className={"row"}>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom4} alt={"Seminarroom"} /></p></div>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom5} alt={"Seminarroom"} /></p></div>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom6} alt={"Seminarroom"} /></p></div>
                </div>
            </div>
            <br />
        </div>
    );
}
// <p align="center">As download you will find the current <a href="download/seminarprogram_de.pdf">Seminarprogram</a>.</p>
export function seminarsEnglish(){
    return(
        <div className={"col-lg-12 col-sm-12"}>
            <h2 align="center">Seminars</h2>
            <br />
            <p align="center"> In my porcelain painting seminars, the participants learn the basics of drawing as well as special techniques that are applied when painting different kinds of porcelain pieces. The emphasis lies in portraying plants and animals on high-quality white porcelain. Directly after each seminar, the participants can take the artistically painted and fired porcelain objects home with them.
            </p>
            <p align="center">I can offer you four different types of seminar: </p>
            <ul style={{"text-align":"center"}}>
                <li><a href="#seminar_1">Basic seminars</a> where I teach the basics of the art of painting in preparation for porcelain painting.,</li>
                <li><a href="#seminar_2">Topical seminars</a> where I define the topics to work with</li>
                <li><a href="#seminar_3">Intensive seminars</a> in Bad Pyrmont where you choose the topics yourself.</li>
                <li><a href="#seminar_4">Free-choice seminars international</a> near you, where we work with topics that have been agreed on individually.
                </li>
            </ul>

            <br />
            <hr />
            <br />
            <div id="seminar_1">
                <h2 align="center">Basic seminars</h2>
                <p align="center">
                    I can offer basic seminars where the participants learn the required techniques step by step. First of all, we learn the basics of drawing. In the second step, nature studies take place - you see an example of this on the right - and watercolour techniques are taught. In order to offer high teaching quality, the number of the participants is limited to 6 persons. Each seminar starts at 9:30 a.m. and ends at 5 p.m. During lunch time (1 hour) I can organize a little snack for you on request. Materials such as colours, documentation and brushes have to be taken along by the participants. If you need help when buying the working material, I would be glad to advise you beforehand. In Bad Pyrmont, there are several hotels and guest houses. On request, I would be glad to send you information material about various accommodation facilities.
                </p>
                <p align="center"><img src={seminar1} alt={"Seminarinfo-Image"} /> </p>
            </div>
            <br />
            <hr />
            <br />
            <div id="seminar_2">
                <h2 align="center">Topical seminars</h2>
                <p align="center">For advanced students I conduct topical seminars which last several days. Here, the participants paint porcelain objects on their own following my drafts and samples. Of course, participants can bring forward their own style. In order to offer high teaching quality, the number of the participants is limited to 6 persons. Each seminar starts at 9:30 a.m. and ends at 5 p.m. During lunch time (1 hour) I can organize a little snack for you on request. Firing costs are already included in the participation fee. Materials such as colours and brushes have to be provided by the participants. If you need help when buying the working material, I would be glad to advise you beforehand. In Bad Pyrmont, there are several hotels and guest houses. On request, I would be glad to send you information material about various accommodation facilities.
                </p>
                <p align="center"><img src={seminar2} alt={"SeminarInfo-Image"} /> </p>
            </div>
            <br />
            <hr />
            <br />
            <div id="seminar_3">
                <h2 align="center">Intensive seminars</h2>
                <p align="center">In the course of two days, the participants paint porcelain objects on their own, choosing the subjects individually. In order to intensify teaching and enable the participants to realise the various subjects optimally, the number of participants is limited to 3 persons in the intensive seminars. The seminars take place in my own studio in Bad Pyrmont. Each seminar starts at 9:30 a.m. and ends at 5 p.m. The participation fee comprises firing costs. During lunch time, about 1,5 hours, I organize a snack, which is not included in the participation fee. Materials such as documentation, colours, brushes and work lamp have to be provided by the participants. If you need help when buying the working material, I would be glad to advise you beforehand. Individual subjects shall be agreed upon on beforehand. In Bad Pyrmont, there are several hotels and guest houses. On request, I would be glad to send you information material about various accommodation facilities.
                </p>
            </div>
            <br />
            <hr />
            <br />
            <div id="seminar_4">
                <h2 align="center">Free-choice seminars near you</h2>
                <p align="center">If you want to visit a porcelain painting seminar with your painting group near you, I would be pleased to offer you seminars in your vicinity. In this case, the seminar topics can be defined freely. Of course here the dates, programme and duration of the seminars are agreed on individually. The costs for such a "Free-choice seminar" depend on the respective circumstances such as duration, topical content and place.
                </p>
                <br />
            </div>
            <br />
            <hr />
            <br />
            <div id="seminar_5">
                <h2 align="center">Studio-impressions</h2>
                <p align="center">And now I'll show you some impressions of my Porcelain- and Oil-painting-Studio at Bad Pyrmont, located in the romantic Weserbergland, a place of tales and castles.
                    More informations about Bad Pyrmont you can find <a target="_blank" href="http://bad-pyrmont.de">here</a>.</p>
                <br />
                <div className={"row"}>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom1} alt={"Seminarroom"} /></p></div>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom2} alt={"Seminarroom"} /></p></div>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom3} alt={"Seminarroom"} /></p></div>
                </div>
                <br></br>
                <div className={"row"}>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom4} alt={"Seminarroom"} /></p></div>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom5} alt={"Seminarroom"} /></p></div>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom6} alt={"Seminarroom"} /></p></div>
                </div>
            </div>
            <br />
        </div>
    );
}
// <p align="center">Cliquez <a href="download/seminarprogram_de.pdf">ici</a> pour le téléchargement du programme actuel des stages et du formulaire d'inscription.</p>
export function seminarsFrench(){
    return(
        <div className={"col-lg-12 col-sm-12"}>
            <h2 align="center">Stages</h2>
            <br />
            <p align="center"> Dans mes stages les participants apprennent les bases du dessin ainsi que les diverses techniques relatives à la peinture pour les divers objets en porcelaine. La priorité se base sur la représentation de plantes et d'animaux sur de la porcelaine de haute qualité. A la fin des stages et après cuisson, les participants pourront emporter leurs pièces à la maison. </p>
            <p align="center">Je propose quatre types de stage:  </p>
            <ul style={{"text-align":"center"}}>
                <li><a href="#seminar_1">Stages de base:</a> enseignement des bases de la peinture pour la préparation à la peinture sur porcelaine.</li>
                <li><a href="#seminar_2">Stages à thèmes:</a> travail selon les thèmes définis par moi.</li>
                <li><a href="#seminar_3">Stages intensifs:</a> à Bad Pyrmont, vous êtes libres dans le choix des thèmes.</li>
                <li><a href="#seminar_4">Stages libres:</a> chez vous dans votre région, réalisation des thèmes individuellement choisis après commun accord.
                </li>
            </ul>

            <br />
            <hr />
            <br />
            <div id="seminar_1">
                <h2 align="center">Stages de base</h2>
                <p align="center">
                    Je propose des stages de base, dans lesquels les participants apprennent pas-à-pas les techniques nécessaires. Tout d'abord les bases du dessin sont enseignées. Ensuite des études de la nature sont réalisées - voir l'exemple ci-dessous - et la technique de l'aquarelle est apprise.
                    Le nombre des participants est limité à 6 personnes, afin que le suivi individuel soit garanti.
                    Les horaires des stages sont de 9.30 à 17.00 heures.
                    Durant la pause de midi (1 heure) j'organise un repas léger.
                    Les participants devront amener leur matériel de peinture, couleurs, documents et pinceaux. Je vous conseille volontiers dans le choix du matériel.
                    Vous trouverez un grand choix d'hôtels, d'auberges et d'appartements de vacances à Bad Pyrmont.
                    Sur demande je vous ferai parvenir des informations supplémentaires sur les divers hébergements.

                </p>
                <p align="center"><img src={seminar1} alt={"Seminarinfo-Image"} /> </p>
            </div>
            <br />
            <hr />
            <br />
            <div id="seminar_2">
                <h2 align="center">Stages à thèmes</h2>
                <p align="center">Je propose des stages à thèmes de trois jours pour les avancés. Les participants peindront de manière autonome un objet en porcelaine selon mes ébauches et modèles. Chaque participant peut naturellement y ajouter sa propre note de créativité.

                    Le nombre des participants est limité à 6 personnes, afin que le suivi individuel soit garanti.
                    Les horaires des stages sont de 9.30 à 17.00 heures.
                    Durant la pause de midi (1 heure) j'organise un repas léger.
                    Les participants devront amener leur matériel de peinture, couleurs, documents et pinceaux. Je vous conseille volontiers dans le choix du matériel. Vous trouverez un grand choix d'hôtels, d'auberges et d'appartements de vacances à Bad Pyrmont. Sur demande je vous ferai parvenir des informations supplémentaires sur les divers hébergements

                </p>
                <p align="center"><img src={seminar2} alt={"SeminarInfo-Image"} /> </p>
            </div>
            <br />
            <hr />
            <br />
            <div id="seminar_3">
                <h2 align="center">Stages intensifs</h2>
                <p align="center">En deux jours les participants peindront de manière autonome un objet en porcelaine selon leurs propres idées.
                    Le nombre des participants est limité à 3 personnes, afin que le suivi intensif et individuel soit garanti ainsi qu'une réalisation optimale des divers motifs. Ces stages se déroulent dans mon atelier à Bad Pyrmont.
                    Les horaires des stages sont de 9.30 à 17.00 heures. Les frais de cuisson, les boissons et cafés sont inclus dans le tarif à payer.

                    Durant la pause de midi, environ 1,5 heure, j'organise un repas léger qui n'est pas inclus dans le tarif. Les participants devront amener leur matériel de peinture, couleurs, documents et pinceaux. Je vous conseille volontiers dans le choix du matériel. Les thèmes seront choisis au préalable en commun accord. Vous trouverez un grand choix d'hôtels, d'auberges et d'appartements de vacances à Bad Pyrmont. Sur demande je vous ferai parvenir des informations supplémentaires sur les divers hébergements.

                </p>
            </div>
            <br />
            <hr />
            <br />
            <div id="seminar_4">
                <h2 align="center">Stages libres</h2>
                <p align="center">Au cas où vous aimeriez participer avec votre groupe de peinture à un stage dans un atelier de votre région, je me déplacerai volontiers pour y organiser le stage.
                    Dans ce cas les thèmes du stage seront choisis librement. Bien entendu les dates, le déroulement et la durée des stages seront fixés en commun accord. Le tarif pour un stage libre dépendra de la région, de la durée et du choix des thèmes choisis.  </p>
                <br />
            </div>
            <br />
            <hr />
            <br />
            <div id="seminar_5">
                <h2 align="center">Impressions d'atelier</h2>
                <p align="center">Quelques photos de mon studio à Bad Pyrmont. Cliquez <a target="_blank" href="http://bad-pyrmont.de">ici </a> pour des informations supplémentaires sur Bad Pyrmont.</p>
                <br />
                <div className={"row"}>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom1} alt={"Seminarroom"} /></p></div>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom2} alt={"Seminarroom"} /></p></div>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom3} alt={"Seminarroom"} /></p></div>
                </div>
                <br></br>
                <div className={"row"}>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom4} alt={"Seminarroom"} /></p></div>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom5} alt={"Seminarroom"} /></p></div>
                    <div className={"col-lg-4"}><p align={"center"}><img src={seminarroom6} alt={"Seminarroom"} /></p></div>
                </div>
            </div>
            <br />
        </div>
    );
}