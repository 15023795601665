import React from "react";
import "../../stylesheets/slider.css";
import slider1 from "../../images/image01.jpg";
import slider2 from "../../images/image02.jpg";
import slider3 from "../../images/image03.jpg";
import slider4 from "../../images/image04.jpg";
import slider5 from "../../images/image05.JPG";
import {Link} from "react-router-dom";

class Slider extends React.Component{
    constructor(props){
        super(props);
    }

    state = {
        bio: "/biography",
        imgtitle: "M. Foster Biography"
    }

    async componentDidMount() {
        let bio = "/biography";
        let it = "M. Foster Biography"
        if (this.props.lang === "de") {
            bio = "/biographie";
            it = "M. Foster Biographie";
        }
        if (this.props.lang === "fr") {
            bio = "/biografie_fr";
            it = "M. Foster Biographie";
        }
        await this.setState({bio: bio, imgtitle: it});
    }

    render() {
        return(
            <React-Fragment>
                <div className={"slider"}>
                    <table>
                        <tr>
                            <td><Link to={this.state.bio}><img className={"slider_image"} style={{marginLeft: "15px"}} title={this.state.imgtitle} alt={"Melanie Foster, Portrait by M.Paal Photographie"} src={slider1} /></Link></td>
                            <td><Link to={"/shop"}><img className={"slider_image"} alt={"Buch, M.Foster, Erlebnis Natur"} src={slider2} /></Link></td>
                            <td><Link to={"/shop"}><img className={"slider_image"} alt={"Buch, M.Foster, Roselovers"} src={slider3} /></Link></td>
                            <td><Link to={"/shop"}><img className={"slider_image"} alt={"Buch, M.Foster, Exotica"} src={slider4} /></Link></td>
                            <td><Link to={"/shop"}><img style={{marginRight: "15px"}} className={"slider_image"} alt={"Buch, M.Foster, Flower Fantasy"} src={slider5} /></Link></td>
                        </tr>
                    </table>
                </div>
            </React-Fragment>
        );
    }
}

export default Slider;