import React from "react";
import "../../stylesheets/common.css"
class Footer extends React.Component{

    render() {
        return(
            <div className={"footer"}>
                <p style={{color: "#54203b", fontSize: "90%"}}>&copy; Melanie Foster, 2019</p>
            </div>
        );
    }
}

export default Footer;