import porcellan1 from "../images/porzellan_1.JPG";
import porcellan2 from "../images/porzellan_2.jpg";
import porcellan3 from "../images/porzellan_3.jpg";
import porcellan4 from "../images/porzellan_4.jpg";
import porcellan5 from "../images/porzellan_5.jpg";
import canvas1 from "../images/canvas_1.jpg";
import canvas2 from "../images/canvas_2.jpg";
import canvas4 from "../images/canvas_4.jpg";
import canvas5 from "../images/canvas_5.jpg";
import React from "react";
import {Link} from "react-router-dom";
import "../stylesheets/common.css";

export function porcelainGerman(){
    return(<div className={"col-md-12"}>


        <h2 align="center">Porzellanmalerei</h2>
        <br />
        <p align="center">Das Veredeln von wei&szlig;em Porzellan durch kunstvolle Malereien ist einer der Schwerpunkte meiner Arbeit. Inspiriert durch die Farbenpracht und die Vielfalt der Natur bemale ich mit Geduld, ruhiger Hand und feinen Pinselstrichen einzelne Objekte, aber auch ganze Service. </p>
        <br />
        <p align="center">Ein kleiner Einblick in meine Werke auf Porzellan:</p>
        <br />
        <div className={"row"}>
            <div className={"col-md-1"}></div>
            <div className={"col-lg-2"}><p align={"center"}><img src={porcellan1} alt={"Porcelain Image"} /></p></div>
                <div className={"col-lg-2"}><p align={"center"}><img src={porcellan2} alt={"Porcelain Image"} /></p></div>
                <div className={"col-lg-2"}><p align={"center"}><img src={porcellan3} alt={"Porcelain Image"} /></p></div>
                <div className={"col-lg-2"}><p align={"center"}><img src={porcellan4} alt={"Porcelain Image"} /></p></div>
                <div className={"col-lg-2"}><p align={"center"}><img src={porcellan5} alt={"Porcelain Image"} /></p></div>
            <div className={"col-md-1"}></div>
        </div>
        <p align="center">Weitere Fotographien finde Sie in meiner <Link to={"/galerie"}><p>Galerie</p></Link>&hellip;</p>
        <br />
        <p align="center">Abh&auml;ngig von Gr&ouml;&szlig;e, Art und Verwendungszweck bemale ich das Porzellan mit:</p>

        <ul align="center" style={{"text-align": "center"}}><li>Tier-, Jagd- und Vogelmotiven</li>
            <li>Landschaftsszenen und Miniaturen</li>
            <li>Pflanzen und Blumen</li>
            <li>Fr&uuml;chten</li>
            <li>Familienwappen</li>
            <li>&hellip;oder ausgefallenen Motiven nach Kundenw&uuml;nschen.</li>
        </ul>
        <br />
        <p align="center">
            Gerne dekoriere ich das bemalte Porzellan zus&auml;tzlich mit Gold- und Silberpr&auml;paraten und f&uuml;hre Figurenstaffagen aus.

            Au&szlig;erdem erg&auml;nze ich fehlende St&uuml;cke in unvollst&auml;ndigen Service und &uuml;berarbeite verbla&szlig;te Vergoldungen nach.

            Auf Anfrage erteile ich Ihnen gerne weitere Informationen zur Porzellanmalerei, derzeit verk&auml;uflichen Porzellanobjekten und zu den Preisen. </p>
        <br /><br /><br /><br /><hr /><br /><br /><br /><br />
        <div id="canvas"></div>
        <h2 align="center">Bildermalerei</h2>
        <br />
        <p align="center">Die Malerei bildet den zweiten Schwerpunkt meiner Arbeit. Bei der Wahl der Motive la&szlig;e ich mich meistens von der Natur anregen. Au&szlig;er detailgetreuen Abbildungen von Pflanzen oder Tieren entstehen auch abstrakte Gem&auml;lde, die durch die Farben und Formen der Natur inspiriert sind.

            Ein weiteres Feld ist die detailgetreue Abbildung technischer Dinge, z.B. Fahrzeuge und Maschinen. Hier kombiniere ich die fotorealistische Darstellung der Motive mit abstrakten Hintergr&uuml;nden.

            Je nach Motiv male ich die Bilder in &Ouml;l, Aquarelltechnik oder Acryl. Meine Erfahrungen im Umgang mit Porzellan und den bei der Porzellanmalerei &uuml;blichen Verzierungen la&szlig;e ich gerne durch Verzierung der Bilder mit Blattgold und der Integration plastischer Elemente einflie&szlig;en. </p>
        <br />
        <p align="center">Einige Beispiele meiner Werke:</p>
        <br />
        <div className={"row"}>
            <div className={"col-lg-3 col-md-6 col-sm-10"}><p align={"center"}><img src={canvas1} alt={"Canvas Image"} /></p></div>
            <div className={"col-lg-3 col-md-6 col-sm-10"}><p align={"center"}><img src={canvas2} alt={"Canvas Image"} /></p></div>
            <div className={"col-lg-3 col-md-6 col-sm-10"}><p align={"center"}><img src={canvas4} alt={"Canvas Image"} /></p></div>
            <div className={"col-lg-3 col-md-6 col-sm-10"}><p align={"center"}><img src={canvas5} alt={"Canvas Image"} /></p></div>
        </div>
        <br />
        <p align="center">Weitere Fotographien finde Sie in meiner <Link to={"/galerie"}><p>Galerie</p></Link>&hellip;</p>
        <br />
        <p align="center">Auf Anfrage werde Ich sehr gerne Ihre Fragen beantworten</p><br />
    </div>);
}

export function porcelainEnglish(){
    return(<div className={"col-md-12"}>


        <h2 align="center">Porcelain Painting</h2>
        <br />
        <p align="center">Refining white porcelain by decorating it with artistic paintings is one of the main fields of my work. Inspired by the blaze of colours and the manifoldness in nature, I enjoy painting individual objects but also whole crockery sets, with patience, a steady hand and fine brushstrokes. </p>
        <br />
        <p align="center">Some examples of my work:</p>
        <br />
        <div className={"row"}>
            <div className={"col-md-1"}></div>
            <div className={"col-lg-2"}><p align={"center"}><img src={porcellan1} alt={"Porcelain Image"} /></p></div>
            <div className={"col-lg-2"}><p align={"center"}><img src={porcellan2} alt={"Porcelain Image"} /></p></div>
            <div className={"col-lg-2"}><p align={"center"}><img src={porcellan3} alt={"Porcelain Image"} /></p></div>
            <div className={"col-lg-2"}><p align={"center"}><img src={porcellan4} alt={"Porcelain Image"} /></p></div>
            <div className={"col-lg-2"}><p align={"center"}><img src={porcellan5} alt={"Porcelain Image"} /></p></div>
            <div className={"col-md-1"}></div>
        </div>
        <p align="center">You can find more pictures in my <Link to={"/gallery"}><p>gallery</p></Link>&hellip;</p>
        <br />
        <p align="center">Depending on size, type and purpose I paint the porcelain with:</p>

        <ul align="center" style={{"text-align": "center"}}><li>Animal, hunting and bird motifs</li>
            <li>Rural scenery and miniatures</li>
            <li>Plants and flowers</li>
            <li>Fruit</li>
            <li>Armorial bearings</li>
            <li>&hellip;or extraordinary motifs on customers' request.</li>
        </ul>
        <br />
        <p align="center">
            As a finishing touch I like to add preparations in gold and silver and perform figure staffage on the painted porcelain. Furthermore I replace missing pieces in incomplete crockery and touch up discoloured gold coating. On request I can provide further information about porcelain painting, presently available porcelain objects and prices.
        </p>
        <br /><br /><br /><br /><hr /><br /><br /><br /><br />
        <div id="canvas"></div>
        <h2 align="center">Picture Painting</h2>
        <br />
        <p align="center">
            Painting constitutes the second focal point of my artistic activity. When choosing the respective subjects, nature is my source of inspiration in most cases. Alongside detailed pictures of plants or animals, I also create abstract paintings, which, in their colours and shapes, have been inspired by nature. Another field of activity is the realistic reproduction of technical objects such as vehicles and machines. Here, I combine the fotorealistic reproduction of these motives with abstract backgrounds. Depending on the subject, I paint the pictures in oil, watercolour or acrylic techniques. My experience resulting from my work with porcelain, where ornamental techniques are applied on many occasions, is often integrated here by using gold leaf ornaments and three-dimensional elements.</p>
        <br />
        <p align="center">Here are some examples of my work:</p>
        <br />
        <div className={"row"}>
            <div className={"col-lg-3 col-md-6 col-sm-10"}><p align={"center"}><img src={canvas1} alt={"Canvas Image"} /></p></div>
            <div className={"col-lg-3 col-md-6 col-sm-10"}><p align={"center"}><img src={canvas2} alt={"Canvas Image"} /></p></div>
            <div className={"col-lg-3 col-md-6 col-sm-10"}><p align={"center"}><img src={canvas4} alt={"Canvas Image"} /></p></div>
            <div className={"col-lg-3 col-md-6 col-sm-10"}><p align={"center"}><img src={canvas5} alt={"Canvas Image"} /></p></div>
        </div>
        <br />
        <p align="center">You can find more pictures in my <Link to={"/gallery"}><p>gallery</p></Link>&hellip;</p>
        <br />
        <p align="center">On request I would be glad to provide further information about paintings and prices.</p><br />
    </div>);

}

export function porcelainFrench(){
    return(<div className={"col-md-12"}>


        <h2 align="center">Peinture sur porcelaine</h2>
        <br />
        <p align="center">L'ennoblissement de la porcelaine blanche par des peintures artistiques est ma première priorité dans mon travail. Inspirée par la multitude des couleurs et la diversité de la nature, je peins avec une grande patience et une main sûre des objets uniques et aussi des services complets avec des traits de pinceau très fins.
        </p>
        <br />
        <p align="center">Un petit aperçu de mes œuvres sur porcelaine:</p>
        <br />
        <div className={"row"}>
            <div className={"col-md-1"}></div>
            <div className={"col-lg-2"}><p align={"center"}><img src={porcellan1} alt={"Porcelain Image"} /></p></div>
            <div className={"col-lg-2"}><p align={"center"}><img src={porcellan2} alt={"Porcelain Image"} /></p></div>
            <div className={"col-lg-2"}><p align={"center"}><img src={porcellan3} alt={"Porcelain Image"} /></p></div>
            <div className={"col-lg-2"}><p align={"center"}><img src={porcellan4} alt={"Porcelain Image"} /></p></div>
            <div className={"col-lg-2"}><p align={"center"}><img src={porcellan5} alt={"Porcelain Image"} /></p></div>
            <div className={"col-md-1"}></div>
        </div>
        <p align="center">D'autres photos se trouvent dans ma <Link to={"/gallery"}><p>gallerie</p></Link>&hellip;</p>
        <br />
        <p align="center">Selon la taille, le genre et l'utilisation je peins la porcelaine avec des</p>

        <ul align="center" style={{"text-align": "center"}}><li>Motifs d'animaux, de chasse et d'oiseaux</li>
            <li>Paysages et miniatures</li>
            <li>Plantes et fleurs</li>
            <li>Fruits</li>
            <li>Blasons de famille</li>
            <li>&hellip;Ou des motifs selon les demandes des clients.</li>
        </ul>
        <br />
        <p align="center">
            J'aime embellir la porcelaine peinte avec des métaux précieux comme l'or ou le platine et exécute aussi la décoration de figurines. D'autre part je complète des services par des pièces manquantes et peut aussi effectuer une nouvelle couche d'or sur des ors blêmis. Sur demande je fournis des informations supplémentaires sur la peinture sur porcelaine, les objets en porcelaine en vente et leur prix.  </p>
        <br /><br /><br /><br /><hr /><br /><br /><br /><br />
        <div id="canvas"></div>
        <h2 align="center">Peinture de tableaux</h2>
        <br />
        <p align="center">
            Ce genre de peinture est la deuxième priorité de mon travail. Je prends mon inspiration pour le choix des motifs dans la nature. Outre les réalisations minutieusement représentées de plantes et d'animaux je crée des tableaux abstraits dont les couleurs et formes sont inspirées de la nature. Un autre volet de mon activité est la représentation photoréaliste d'objets techniques comme p.ex. de voitures ou de machines. Ici je combine ces motifs photo-réalistes avec des fonds abstraits. Selon les motifs, je peints les tableaux à l'huile, à l'aquarelle ou à l'acrylique.
        </p>   <br />
        <p align="center">Quelques exemples de mes œuvres:</p>
        <br />
        <div className={"row"}>
            <div className={"col-lg-3 col-md-6 col-sm-10"}><p align={"center"}><img src={canvas1} alt={"Canvas Image"} /></p></div>
            <div className={"col-lg-3 col-md-6 col-sm-10"}><p align={"center"}><img src={canvas2} alt={"Canvas Image"} /></p></div>
            <div className={"col-lg-3 col-md-6 col-sm-10"}><p align={"center"}><img src={canvas4} alt={"Canvas Image"} /></p></div>
            <div className={"col-lg-3 col-md-6 col-sm-10"}><p align={"center"}><img src={canvas5} alt={"Canvas Image"} /></p></div>
        </div>
        <br />
        <p align="center">
            D'autres photos de trouvent dans ma <Link to={"/gallery"}><p>gallerie</p></Link>&hellip;</p>
        <br />
        <p align="center">Je répondrai volontiers à toutes vos questions.</p><br />
    </div>);

}