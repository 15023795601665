import React from "react";
import FixedTopBar from "../components/common/fixedTopBar";
import {biographyDE, biographyEN, biographyFR} from "../texts/biography";
import QuoteModule from "./QuoteModule";
import MediaBar from "../components/mediabar/mediabar";

class Quotes extends React.Component{
    constructor(props){
        super(props);
    }
    state = {
        header: "",
        notice: ""
    }
    async componentDidMount() {
        switch (this.props.lang) {
            case "de":
                await this.setState({header: "Schülerzitate"});
                break;
            case "en":
                await this.setState({header: "Quotes of my students"});
                break;
            case "fr":
                await this.setState({header: "Citations d'étudiants"});
                break;
            default:
                await this.setState({header: <h3>Error!</h3>});
                console.log(this.props.lang);
        }
    }

    render() {
        return(
            <div>
                <MediaBar />
                <FixedTopBar lang={this.props.lang}/>
                <p>_</p>
                <div className={"content"}>
                    <div className={"col-lg-12 col-md-11 col-sm-12"}>
                        <h2 align={"center"}>{this.state.header}</h2>
                        <QuoteModule student={0} />
                        <QuoteModule student={1} />
                        <QuoteModule student={2} />
                        <QuoteModule student={3} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Quotes;
