import React from "react";
import FixedTopBar from "../common/fixedTopBar";
import "../../stylesheets/common.css";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import './timeline.css';
import {timelineItems} from "../../services/welcomeTimeline";
import MediaBar from "../mediabar/mediabar";

class Welcome extends React.Component{
    constructor(props){
        super(props);
    }
    state = {
        timelineItems: [],
        title: "Aktuelle Events und News"
    }

    async componentDidMount() {
        await this.setState({
            timelineItems: timelineItems.filter(entry => entry.language === this.props.lang)
        })
        switch(this.props.lang){
            case "en":
                this.setState({title: "Current events and news"})
                break;
            case "fr":
                this.setState({title: "Evénements, Expositions et Actualités"})
        }
    }

    render() {
        return(
            <React-Fragment>
                <MediaBar />
                <FixedTopBar lang={this.props.lang} />
                <p>_</p>
                <div className={"content"}>
                    <h2 align={"center"}>{this.state.title}</h2>
                    <VerticalTimeline>
                        {this.state.timelineItems.map((item) =>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#54203b', color: '#fff' }}
                                contentArrowStyle={{ borderRight: '7px solid  #54203b' }}
                                date={item.date}
                                iconStyle={{ background: '#54203b', color: '#fff' }}
                            >
                                <h3 className="vertical-timeline-element-title" style={{color: "#fff"}}>{item.title}</h3>
                                <h4 className="vertical-timeline-element-subtitle">{item.subtitle}</h4>
                                <p>{item.content}</p>
                                <a target={"blank"} href={item.linkpath} style={{color: "#007BFF"}}>{item.linkname}</a>
                            </VerticalTimelineElement>)}
                    </VerticalTimeline>
                </div>

            </React-Fragment>

        );
    }
}

export default Welcome;
