import React from "react";

class FourOfour extends React.Component{

    render() {
        return (
            <div>
                <br />
                <p align={"center"}>Sorry this page could not be found!</p>
                <p align={"center"}>Please click <a href={"https://melanie-foster.de"}>here</a> to go to the main page.</p>
                <br />
                <br />
                <p align={"center"}>Entschuldigung, diese Seite konnte nicht gefunden werden!</p>



                <p align={"center"}>Klicken Sie <a href={"https://melanie-foster.de"}>hier</a> um auf die Hauptseite zu gelangen.</p>
            </div>
        );
    }

}

export default FourOfour;
