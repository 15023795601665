import React from 'react';
import {Route, Switch, BrowserRouter as Router} from "react-router-dom";
import LanguageSelect from "./components/languageSelect/languageSelect";
import Footer from "./components/common/footer";
import Welcome from "./components/welcome/welcome";
import Porcelain from "./components/porcelain/porcelain";
import Seminars from "./components/seminars/seminars";
import Impress from "./components/impress/impress";
import Gallery from "./components/gallery/gallery";
import Biography from "./components/biography/biography";
import Quotes from "./quotes/Quotes";
import FourOfour from "./components/error/fourOfour";


function App() {
  return (
    <React-Fragment>
      <Router>
        <Switch>
          <Route path="/" exact render={() => <LanguageSelect />} />
          <Route path="/welcome" exact render={() => <Welcome lang={"en"} />} />
            <Route path="/willkommen" exact render={() => <Welcome lang={"de"} />} />
            <Route path="/bonjour" exact render={() => <Welcome lang={"fr"} />} />
            <Route path="/shop" exact render={() => window.location = 'https://atelierfuermalerei.de'} />
          <Route path="/malerei" exact render={() => <Porcelain lang={"de"} />} />
            <Route path="/painting" exact render={() => <Porcelain lang={"en"} />} />
            <Route path="/peinture" exact render={() => <Porcelain lang={"fr"} />} />
          <Route path="/seminare" exact render={() => <Seminars lang={"de"} />} />
            <Route path="/seminars" exact render={() => <Seminars lang={"en"} />} />
            <Route path="/stages" exact render={() => <Seminars lang={"fr"} />} />
          <Route path="/impressum" exact render={() => <Impress lang={"de"} />} />
          <Route path="/contact" exact render={() => <Impress lang={"en"} />} />
          <Route path="/empreinte" exact render={() => <Impress lang={"fr"} />} />
          <Route path="/galerie" exact render={() => <Gallery lang={"de"} />} />
            <Route path="/gallery" exact render={() => <Gallery lang={"en"} />} />
            <Route path="/galerie_fr" exact render={() => <Gallery lang={"fr"} />} />
           <Route path="/biographie" exact render={() => <Biography lang={"de"} />} />
            <Route path="/biography" exact render={() => <Biography lang={"en"} />} />
            <Route path="/biografie_fr" exact render={() => <Biography lang={"fr"} />} />
           <Route path="/zitate" exact render={() => <Quotes lang={"de"} />} />
            <Route path="/quotes" exact render={() => <Quotes lang={"en"} />} />
            <Route path="/citations" exact render={() => <Quotes lang={"fr"} />} />
            <Route render={()=><FourOfour />} />
        </Switch>
      </Router>
        <Footer />
    </React-Fragment>
  );
}

export default App;
