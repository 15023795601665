import gal_1 from "../../images/gallery/1.jpg"
import gal_2 from "../../images/gallery/4.jpg"
import gal_3 from "../../images/gallery/5.jpg"
import gal_4 from "../../images/gallery/6.jpg"
import gal_5 from "../../images/gallery/7.jpg"
import gal_6 from "../../images/gallery/8.jpg"
import gal_7 from "../../images/gallery/9.jpg"
import gal_8 from "../../images/gallery/10.JPG"
import gal_11 from "../../images/gallery/11.jpg"
import gal_18 from "../../images/gallery/18.jpg"
import gal_19 from "../../images/gallery/19.jpg"
import gal_16 from "../../images/gallery/16.JPG"
import gal_14 from "../../images/gallery/14.jpg"
import gal_21 from "../../images/gallery/21.jpg"
import gal_22 from "../../images/gallery/22.jpg"
import gal_23 from "../../images/gallery/66.jpg"
//
import gal_30 from "../../images/gallery/30.jpg"
import gal_31 from "../../images/gallery/31.JPG"
import gal_32 from "../../images/gallery/32.jpg"
import gal_33 from "../../images/gallery/33.jpg"
import gal_34 from "../../images/gallery/34.jpg"
import gal_35 from "../../images/gallery/35.jpg"
import gal_36 from "../../images/gallery/36.JPG"
import gal_37 from "../../images/gallery/37.JPG"
import gal_38 from "../../images/gallery/38.jpg"
import gal_39 from "../../images/gallery/39.JPG"
import gal_40 from "../../images/gallery/40.JPG"
import gal_41 from "../../images/gallery/41.JPG"
import gal_42 from "../../images/gallery/42.jpg"
import gal_43 from "../../images/gallery/43.jpg"
import gal_44 from "../../images/gallery/44.jpg"
import gal_45 from "../../images/gallery/45.jpg"
import gal_46 from "../../images/gallery/46.jpg"
import gal_47 from "../../images/gallery/47.JPG"
import gal_48 from "../../images/gallery/48.jpg"
import gal_49 from "../../images/gallery/49.JPG"
import gal_50 from "../../images/gallery/50.jpg"
import gal_51 from "../../images/gallery/51.jpg"
//
import gal_60 from "../../images/gallery/60.jpg"
import gal_61 from "../../images/gallery/61.JPG"
import gal_62 from "../../images/gallery/62.jpg"
import gal_63 from "../../images/gallery/63.jpg"
import gal_64 from "../../images/gallery/64.JPG"
import gal_65 from "../../images/gallery/65.jpg"

export const photos = [
    {
        src: gal_32,
        width: 3,
        height: 4
    },
    {
        src: gal_42,
        width: 4,
        height: 4
    },
    {
        src: gal_51,
        width: 4,
        height: 3
    },
    {
        src: gal_48,
        width: 2,
        height: 3
    },
    {
        src: gal_33,
        width: 5,
        height: 4
    },
    {
        src: gal_35,
        width: 4,
        height: 4
    },
    {
        src: gal_30,
        width: 3,
        height: 4
    },
    {
        src: gal_23,
        width: 4,
        height: 4
    },
    {
        src: gal_19,
        width: 5,
        height: 2
    },
    {
        src: gal_34,
        width: 5,
        height: 4
    },
    {
        src: gal_31,
        width: 4,
        height: 4
    },
    {
        src: gal_14,
        width: 4,
        height: 3
    },
    {
        src: gal_4,
        width: 3,
        height: 2
    },
    {
        src: gal_43,
        width: 3,
        height: 4
    },
    {
        src: gal_16,
        width: 4,
        height: 3
    },
    {
        src: gal_37,
        width: 3,
        height: 4
    },
    {
        src: gal_36,
        width: 3,
        height: 3
    },
    {
        src: gal_18,
        width: 4,
        height: 3
    },
    {
        src: gal_11,
        width: 4,
        height: 3
    },
    {
        src: gal_41,
        width: 4,
        height: 4
    },
    {
        src: gal_22,
        width: 4,
        height: 4
    },
    {
        src: gal_21,
        width: 4,
        height: 4
    },
    {
        src: gal_2,
        width: 4,
        height: 3
    },
    {
        src: gal_46,
        width: 4,
        height: 3
    },
    {
        src: gal_44,
        width: 4,
        height: 3
    },
    {
        src: gal_60,
        width: 4,
        height: 3
    },
    {
        src: gal_61,
        width: 4,
        height: 3
    },
    {
        src: gal_62,
        width: 5,
        height: 3
    },
    {
        src: gal_63,
        width: 4,
        height: 3
    },
    {
        src: gal_64,
        width: 5,
        height: 3
    },
    {
        src: gal_65,
        width: 6,
        height: 3
    },
];
