import React from "react";
import {navBarItems} from "../../services/NavBarItems";
import {Link} from "react-router-dom";
import "../../stylesheets/menu.css";
import "../../stylesheets/common.css";

class NavBar extends React.Component{

    constructor(props){
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    state ={
        menuItems: [],
        menuTitle: "Menu",
        menuShow: "menu",
        menuHelp: "Klicken zum öffnen"
    };

    async componentDidMount() {
        if (this.props.lang == null) {
            await this.setState(
                {language: "en"}
            )
        } else {
           await this.setState({language: this.props.lang})
        }
        switch(this.state.language){
            case "de":
                this.setState({
                    menuTitle: "Menü",
                    menuHelp: "Klicken zum öffnen"
                });
                break;
            case "en":
                this.setState({
                    menuTitle: "Menu",
                    menuHelp: "Tap to open"
                });
                break;
            case "fr":
                this.setState({
                    menuTitle: "Menu",
                    menuHelp: "appuyez pour ouvrir"
                });
                break;
            default:
                break;
        }

        await this.setState(
            {menuItems: navBarItems.filter(entry => entry.language === this.state.language)}
        );
    }

    toggleMenu(){
        switch (this.state.menuShow) {
            case "menu":
                this.setState({menuShow: "menuMobile"});
                break;
            case "menuMobile":
                this.setState({menuShow: "menu"});
                break;
            default:
                this.setState({menuShow: "menuMobile"});
                break;
        }
    }

    render() {
        return(
            <React-Fragment>
                <div className={"actionbar"} onClick={this.toggleMenu}><p>{this.state.menuTitle}<span style={{"float":"right", "font-size":"80%","padding-top":"3px","padding-right": "5px"}}>{this.state.menuHelp}</span></p></div>
                <div className={this.state.menuShow}>
                    <ul>
                        {this.state.menuItems.map((item) => (<li className={"menuItem"}><Link to={item.link}>{item.text}</Link></li>))}
                    </ul>
                </div>

            </React-Fragment>
        )
    }
}

export default NavBar;