import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";



ReactDOM.render(
    <BrowserRouter>
        <div onContextMenu={(e) => {e.preventDefault()}}>
            <App />
        </div>
    </BrowserRouter>,
    document.getElementById("root")
);


