import React from "react";
import FixedTopBar from "../common/fixedTopBar";
import "../../stylesheets/common.css";
import GalleryApplet from "./gallerystuff";
import MediaBar from "../mediabar/mediabar";


class Gallery extends React.Component{
    constructor(props) {
        super(props);
    }

    state = {
        header: ""
    };


    async componentDidMount() {
        switch (this.props.lang) {
            case "de":
                await this.setState({header: "Galerie"});
                break;
            case "en":
                await this.setState({header: "Gallery"});
                break;
            case "fr":
                await this.setState({header: "Galerie"});
                break;
            default:
                await this.setState({header: <h3>Error!</h3>});
                console.log(this.props.lang);
        }
    }

    render() {
        return(
            <React-Fragment>
                <MediaBar />
                <FixedTopBar lang={this.props.lang} />
                <p>_</p>
                <div className={"content"}>
                    <div className={"col-lg-12 col-sm-12"}>
                        <h2 align={"center"}>{this.state.header}</h2>
                        <br/>
                        <GalleryApplet/>

                    </div>
                </div>

            </React-Fragment>

        );
    }
}

export default Gallery;
