import React from "react";
import FixedTopBar from "../common/fixedTopBar";
import "../../stylesheets/common.css";
import {porcelainEnglish, porcelainFrench, porcelainGerman} from "../../texts/porcelain";
import MediaBar from "../mediabar/mediabar";

class Porcelain extends React.Component{
    constructor(props){
        super(props);
    }
    getContent(){
        switch (this.props.lang) {
            case "de":
                return porcelainGerman();
            case "en":
                return porcelainEnglish();
            case "fr":
                return porcelainFrench();
            default:
                return porcelainGerman();
        }
    }

    render() {
        return(
            <React-Fragment>
                <MediaBar />
                <FixedTopBar lang={this.props.lang} />
                <p>_</p>
                <div className={"content"}>
                    {this.getContent()}
                </div>

            </React-Fragment>

        );
    }
}

export default Porcelain;
