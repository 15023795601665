import React from "react";
import "../../stylesheets/header.css"
import logo from "../../images/logo.png";
import {Link} from "react-router-dom";

class Header extends React.Component{
    render() {
        return(<div id="header" className={"header"}>
            <p align="center"><Link to={"/"}><img alt={"logo"} src={logo} /></Link></p>

        </div>);
    }
}
export default Header;