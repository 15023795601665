import React from "react";
import imgStud_1 from "../images/quotes/student_1.JPG";
import imgStud_2 from "../images/quotes/student_2.jpg";
import imgStud_3 from "../images/quotes/student_3.jpg";
import imgStud_4 from "../images/quotes/student_4.jpeg";
import "../stylesheets/quotes.css";
import {quoteInformations} from "../texts/quotes";

class QuoteModule extends React.Component{
    constructor(props){
        super(props);
    }

    state = {
        images: []
    }

    async componentDidMount() {
        await this.setState({images: [imgStud_1, imgStud_2, imgStud_3, imgStud_4]})
    }


    render() {
        //Bild rechts:
        if(this.props.student === 1 || this.props.student === 3){
            return(
                <div>
                    <br />
                    <br />
                        <div className={"row"}>
                            <div className={"col-lg-5 col-md-5 col-sm-12"}></div>
                            <div className={"colg-lg-3 col-md-3 col-sm-12 qrightfirst"}><h3 align={"center"} className={"studentName"}>{quoteInformations[this.props.student].name}</h3></div>
                            <div className={"col-lg-1 col-md-1 col-sm-12"}></div>
                            <div className={"col-lg-2 col-md-3 col-sm-12"}><p align={"center"}><img className={"studentImage"} src={this.state.images[this.props.student]} alt={"Portrait of Student"} /></p></div>
                            <div className={"colg-lg-3 col-md-3 col-sm-12 qrightsecond"}><h3 align={"center"} className={"studentName"}>{quoteInformations[this.props.student].name}</h3></div>
                        </div>
                        <br />
                        <br />
                        <div className={"col-lg-12 col-sm-12"}><p>{quoteInformations[this.props.student].text}</p></div>
                    <br />
                    <hr />
                </div>
            );
        }
        else{
            //Bild links:
            return(
                <div>
                    <br />
                    <br />
                    <div className={"row"}>
                        <div className={"col-lg-2 col-md-3 col-sm-12"}><p align={"center"}><img className={"studentImage"} src={this.state.images[this.props.student]} alt={"Portrait of Student"} /></p></div>
                        <div className={"col-lg-1 col-md-1 col-sm-12"}></div>
                        <div className={"col-lg-3 col-sm-12"}><h3 align="center" className={"studentName"}>{quoteInformations[this.props.student].name}</h3></div>
                    </div>
                    <br />
                    <br />
                    <div className={"col-lg-12 col-sm-12"}><p>{quoteInformations[this.props.student].text}</p></div>
                    <br />
                    <hr />
                </div>

        );
        }

    }
}

export default QuoteModule;