import React from "react";
import FixedTopBar from "../common/fixedTopBar";
import "../../stylesheets/common.css";
import {seminarsEnglish, seminarsFrench, seminarsGerman} from "../../texts/seminars";
import MediaBar from "../mediabar/mediabar";

class Seminars extends React.Component{
    constructor(props){
        super(props);
    }
    getContent(){
        switch (this.props.lang) {
            case "de":
                return seminarsGerman();
            case "en":
                return seminarsEnglish();
            case "fr":
                return seminarsFrench();
            default:
                return seminarsGerman();
        }
    }

    render() {
        return(
            <React-Fragment>
                <MediaBar />
                <FixedTopBar lang={this.props.lang} />
                <p>_</p>
                <div className={"content"}>
                    {this.getContent()}
                </div>

            </React-Fragment>

        );
    }
}

export default Seminars;
