export const navBarItems = [
    {language: "de", text: "Aktuelles", link: "/willkommen"},
    {language: "de", text: "Malerei", link: "/malerei"},
    {language: "de", text: "Seminare", link: "/seminare"},
    {language: "de", text: "Schülerzitate", link: "/zitate"},
    {language: "de", text: "Bildergalerie", link: "/galerie"},
    {language: "de", text: "Shop", link: "/shop"},
    {language: "de", text: "Impressum", link: "/impressum"},
    {language: "en", text: "News", link: "/welcome"},
    {language: "en", text: "Painting", link: "/painting"},
    {language: "en", text: "Seminars", link: "/seminars"},
    {language: "en", text: "Student Quotes", link: "/quotes"},
    {language: "en", text: "Gallery", link: "/gallery"},
    {language: "en", text: "Shop", link: "/shop"},
    {language: "en", text: "Imprint", link: "/contact"},
    {language: "fr", text: "News", link: "/bonjour"},
    {language: "fr", text: "Peinture", link: "/peinture"},
    {language: "fr", text: "Stages", link: "/stages"},
    {language: "fr", text: "Citations d'étudiants", link: "/citations"},
    {language: "fr", text: "Galerie", link: "/galerie_fr"},
    {language: "fr", text: "Shop", link: "/shop"},
    {language: "fr", text: "Mentions légales", link: "/empreinte"}
    ];