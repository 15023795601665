import React from "react";
import "../stylesheets/common.css";

export function imprintGerman(){
    return(
        <div className={"col-lg-12 col-sm-12"}>
            <h2 align="center">Kontakt / Impressum</h2>
            <br></br>
            <p align="center">Atelier f&uuml;r Malerei<br></br>Melanie Foster<br></br>Potsdamer Str.11<br></br>31812 Bad Pyrmont<br></br>Deutschland</p><br></br>
            <p align="center">Festnetz:<br></br>0049 (0)5281 160 60 48</p>
            <p align="center">Fax:<br></br>0049 (0)5281 160 60 39</p>
            <p align="center">Mobil:<br></br>0049 (0)160 760 41 75</p><br></br>
            <p align="center">E-Mail:<br></br>info@melanie-foster.de</p><br></br>
            <hr />
            <br></br>
            <p align="center">F&uuml;r diese Website verantwortlich ist: Robin Foster.</p><br></br>
    <p align="center">Diese Website, samt Design und Texten, wurde von <a href="mailto:robin.foster@yahoo.de"><b>Robin Foster</b></a> erschaffen.</p><br></br>
    <p align="center">Das Portrait von Melanie Foster wurde von <b>M.Paal PHOTOGRAPHIE M&uuml;nchen</b> aufgenommen</p><br></br><p align="center">Die Flaggen-Buttons stammen von: <a target="_blank" href="http://icondrawer.com"><b>icondrawer.com</b></a></p><br></br>
    <h3 align="center">Urheberrecht</h3>
    <p align="center">Das Copyright f&uuml;r ver&ouml;ffentlichte, von Melanie Foster selbst erstellte Grafiken, Bilder und Texte bleibt allein beim Atelier f&uuml;r Malerei. Eine Vervielf&auml;ltigung oder Verwendung der Grafiken, Bilder und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdr&uuml;ckliche Zustimmung des Aterliers f&uuml;r Malerei nicht gestattet.</p>
    <h3 align="center">Verlinkungen</h3>
    <p align="center">Diese Webseiten enthalten Links zu Webseiten anderer Anbieter. Das Atelier f&uuml;r Malerei hat sich zum Zeitpunkt der Linksetzung davon &uuml;berzeugt, da&szlig; die direkt verkn&uuml;pften Seiten frei von illegalen Inhalten waren. Das Atelier f&uuml;r Malerei hat jedoch keinerlei Einflu&szlig; auf die Inhalte der verkn&uuml;pften Seiten und kann diese nicht fortlaufend kontrollieren. Daher &uuml;bernimmt das Atelier f&uuml;r Malerei keine Haftung f&uuml;r Inhalte der verkn&uuml;pften Seiten, die nach der Linksetzung ver&auml;ndert wurden.</p>
    <h3 align="center">Haftungsauschuss</h3>
    <p align="center">Die Inhalte dieser Internetseiten wurden sorgf&auml;ltig gepr&uuml;ft und nach bestem Wissen erstellt. Vom Atelier f&uuml;r Malerei kann jedoch keinerlei Gew&auml;hr f&uuml;r die Korrektheit, Vollst&auml;ndigkeit, Aktualit&auml;t oder Qualit&auml;t der bereitgestellten Informationen &uuml;bernommen werden. Haftungsanspr&uuml;che gegen das Atelier f&uuml;r Malerei, die sich auf Sch&auml;den materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollst&auml;ndiger Informationen verursacht wurden sind grunds&auml;tzlich ausgeschlo&szlig;en, sofern kein nachweislich vors&auml;tzliches oder grob fahrl&auml;&szlig;iges Verschulden vorliegt. Namentlich gekennzeichnete Beitr&auml;ge spiegeln nicht unbedingt die Meinung des Ateliers f&uuml;r Malerei wieder.</p>
    <h3 align="center">Datenschutz</h3>
    <p align="center">Die verantwortliche Person f&uuml;r die Datenverarbeitung auf dieser Website ist:
        <br></br><br></br>
        Atelier f&uuml;r Malerei<br></br>
        Melanie Foster<br></br>
        Potsdamer Str.11<br></br>
        31812 Bad Pyrmont<br></br>
        <br></br><br></br>
                Die verantwortliche Person entscheidet allein &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. &auml;.).
        <br></br><br></br>
                    <b>Widerruf Ihrer Einwilligung zur Datenverarbeitung</b>
        <br></br><br></br>
                        Nur mit Ihrer ausdr&uuml;cklichen Einwilligung sind bestimmte Vorg&auml;nge der Datenverarbeitung m&ouml;glich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit m&ouml;glich. F&uuml;r den Widerruf gen&uuml;gt eine formlose Mitteilung per E-Mail. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
        <br></br><br></br>
                            <b>Recht auf Beschwerde bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde</b>
        <br></br><br></br>
                                Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Versto&szlig;es ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu. Zust&auml;ndige Aufsichtsbeh&ouml;rde bez&uuml;glich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: https://www.bfdi.bund.de/DE/ Infothek/Anschriften_Links/ anschriften_links-node.html .
        <br></br><br></br>
                                    <b>Recht auf Auskunft, Berichtigung, Sperrung, L&ouml;schung</b>
        <br></br><br></br>
                                        Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder L&ouml;schung dieser Daten. Diesbez&uuml;glich und auch zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit &uuml;ber die im Impressum aufgef&uuml;hrten Kontaktm&ouml;glichkeiten an uns wenden.
        <br></br><br></br>
        <b>Daten&uuml;bermittlung bei Vertragsschluss f&uuml;r Warenkauf und Warenversand</b><br></br><br></br>
                                            Personenbezogene Daten werden nur an Dritte nur &uuml;bermittelt, sofern eine Notwendigkeit im Rahmen der Vertragsabwicklung besteht. Dritte k&ouml;nnen beispielsweise Bezahldienstleister oder Logistikunternehmen sein. Eine weitergehende &Uuml;bermittlung der Daten findet nicht statt bzw. nur dann, wenn Sie dieser ausdr&uuml;cklich zugestimmt haben.
                                            Grundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erf&uuml;llung eines Vertrags oder vorvertraglicher Ma&szlig;nahmen gestattet.

            <br></br><br></br>
                                                <b>Youtube</b>
            <br></br><br></br>
                                                    Wir verwenden den Anbieter YouTube LLC, 901 Cherry Ave., San Bruno, CA 94066, USA, um eingebettete Videos darstellen zu k&ouml;nnen. Beim Aufruf unserer Website nimmt ihr Computer Verbindung zu den Servern dieser Platform auf, damit die jeweiligen Videos abgespielt werden k&ouml;nnen.
                                                    Dabei ist es YouTube m&ouml;glich Ihre IP-Adresse mitzulesen und zu speichern, sowie Cookies auf ihrem Rechner abzulegen. Als Tochterunternehmen von Google finden Sie die Datenschutzerkl&auml;rung dieses Dienstes unter https://policies.google.com/privacy?hl=de .

            <br></br><br></br>
                                                        <b>Cookies</b>
            <br></br><br></br>
                                                            Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endger&auml;t speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.

                                                            Einige Cookies sind "Session-Cookies." Solche Cookies werden nach Ende Ihrer Browser-Sitzung von selbst gel&ouml;scht.

                                                            Mit einem modernen Webbrowser k&ouml;nnen Sie das Setzen von Cookies &uuml;berwachen, einschr&auml;nken oder unterbinden. Die Deaktivierung von Cookies kann eine eingeschr&auml;nkte Funktionalit&auml;t unserer Website zur Folge haben.

                                                            Das Setzen von Cookies, die zur Aus&uuml;bung elektronischer Kommunikationsvorg&auml;nge oder der Bereitstellung bestimmter, von Ihnen erw&uuml;nschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste.
            <br></br>
    </p>
        </div>
    )
}

export function imprintEnglish(){
    return(
        <div className={"col-lg-12 col-sm-12"}>
            <h2 align="center">Contact / Imprint</h2>
            <br />
            <p align="center">Atelier f&uuml;r Malerei<br></br>Melanie Foster<br></br>Potsdamer Str.11<br></br>31812 Bad Pyrmont<br></br>Deutschland</p><br />
            <p align="center">Telephone:<br></br>0049 (0)5281 160 60 48</p>
            <p align="center">Fax:<br></br>0049 (0)5281 160 60 39</p>
            <p align="center">Mobile:<br></br>0049 (0)160 760 41 75</p><br />
            <p align="center">E-Mail:<br></br>info@melanie-foster.de</p><br />
            <hr />
            <br />
            <p align="center">
                Responsible for this website is: Robin Foster.</p><br/>
    <p align="center">This website, including design and text, was created by <a href="mailto:robin.foster@yahoo.de"><b>Robin Foster</b></a>.</p><br />
    <p align="center">The portrait of Melanie Foster was recorded by <b>M.Paal PHOTOGRAPHIE M&uuml;nchen</b></p><br/>
    <h3 align="center">Copyright</h3>
    <p align="center">The copyright relating to published graphics, paintings and texts produced by Melanie Foster herself shall remain exclusively with the Atelier f&uuml;r Malerei. Reproduction or use of these graphics, paintings and texts in other electronic or printed publications shall not be permitted without explicit consent by the Atelier f&uuml;r Malerei.</p><h3 align="center">Notes concerning links</h3>
    <p align="center">These Websites contain links to Websites of other providers. The Atelier f&uuml;r Malerei made sure that, at the time of link creation, the directly connected pages did not contain any illegal contents. However, the Atelier f&uuml;r Malerei has no influence whatsoever on the contents of the connected pages and is not able to control these continuously. Therefore the Atelier f&uuml;r Malerei does not assume any liability for contents of the connected pages that were changed after establishing the link.
    </p>
    <h3 align="center">Notes concerning the exclusion of liability</h3>
    <p align="center">The contents of these Internet pages have been checked thoroughly and drawn up to the best of our knowledge. The Atelier f&uuml;r Malerei can, however, not give any guarantee concerning correctness, completeness, actuality or quality of the provided information. Legal claims for liability against the Atelier f&uuml;r Malerei relating to physical or non-physical damages caused by the use or non-use of the offered information and/or by using faulty or incomplete information, are explicitly excluded, unless willful acts or gross negligence can be proven Contributions by name do not necessarily express the opinion of the Atelier f&uuml;r Malerei.</p>
    <h3 align="center">Cookies</h3>
    <p align="center">We are only using cookies to let the website present you the content in your selected language. Personal data is <b>not</b> collected.</p>


        </div>

    );
}

export function imprintFrench(){
    return(
        <div className={"col-lg-12 col-sm-12"}>
            <h2 align="center">Contact / Mentions l&eacute;gales</h2>
            <br />
            <p align="center">Atelier f&uuml;r Malerei<br></br>Melanie Foster<br></br>Potsdamer Str.11<br></br>31812 Bad Pyrmont<br></br>Allemagne</p><br />
            <p align="center">T&eacute;l&eacute;phone fixe:<br></br>0049 (0)5281 160 60 48</p>
            <p align="center">Fax:<br></br>0049 (0)5281 160 60 39</p>
            <p align="center">Mobile:<br></br>0049 (0)160 760 41 75</p><br />
            <p align="center">E-Mail:<br></br>info@melanie-foster.de</p><br/>
            <hr />
            <br />
            <p align="center">Responsable pour cette page Web : Robin Foster.</p><br />
        <p align="center">Ce site, design et textes inclus, a &eacute;t&eacute; cr&eacute;&eacute; par <a href="mailto:robin.foster@yahoo.de"><b>Robin Foster</b></a></p><br />
         <p align="center">Traduction fran&ccedil;aise : Yvonne Hames - Ao&ucirc;t 2016</p><br />
         <p align="center">Le portrait de Melanie Foster a &eacute;t&eacute; photographi&eacute; par M.PaalPHOTOGRAPHIE, Munich.</p><br/><p align="center">Flag-Buttons designed by: <a target="_blank" href="http://icondrawer.com"><b>icondrawer.com</b></a></p><br/>
        <h3 align="center">Droits d'auteur</h3>
        <p align="center">Les droits d'auteur pour les graphiques, photos et textes cr&eacute;&eacute;s par Melanie
        Foster reste la propri&eacute;t&eacute; unique de &laquo; Atelier f&uuml;r Malerei &raquo;. Toutes
        reproductions, m&ecirc;me partielles, ou l'utilisation des graphiques, photos et
        textes dans des publications &eacute;lectroniques ou imprim&eacute;es ne sont pas
        autoris&eacute;es sans l'accord expresse de &laquo; Atelier f&uuml;r Malerei &raquo;.</p><h3 align="center">Liens</h3>
        <p align="center">Ce site propose des liens vers d'autres pages Web. Au moment de
        l'ajout de ces liens, l'Atelier f&uuml;r Malerei a v&eacute;rifi&eacute; que ces pages sont
        exempts de tout contenu ill&eacute;gal. L'Atelier f&uuml;r Malerei n'a cependant pas
        d'influence sur les pages de ces liens et ne pourra pas les controller
        continuellement. De ce fait Atelier f&uuml;r Malerei n'est pas responsable,
        apr&egrave;s activation de ces liens, pour des contenus modifi&eacute;s.</p><h3 align="center">Exclusion de responsabilit&eacute;</h3>
        <p align="center">Les contenus de ces pages Internet ont &eacute;t&eacute; v&eacute;rifi&eacute;s et &eacute;tablis en bonne
        &acirc;me et conscience. L'Atelier f&uuml;r Malerei ne peut pas prendre de
        responsabilit&eacute; pour l'exactitude, l'int&eacute;gralit&eacute;, l'actualit&eacute; ou la qualit&eacute; des
        informations fournies.
        Les droits de responsabilit&eacute; contre Atelier f&uuml;r Malerei concernant des
        d&eacute;g&acirc;ts mat&egrave;riels ou id&eacute;els survenus apr&egrave;s utilisation des informations
        erron&eacute;es ou incompl&egrave;tes sont d'office exclus, pour autant qu'il n'existe
        pas de faute intentionnelle ou de n&eacute;gligence grave. Les articles
        nominatifs ne repr&eacute;sentent pas obligatoirement l'opinion de Atelier f&uuml;r
        Malerei.</p><h3 align="center">Cookies</h3>
        <p align="center">Ce site utilise des cookies uniquement pour enregistrer la langue choisie.
        Des informations personnelles <b>ne sont pas</b> relev&eacute;es.</p>


        </div>

    );
}