import React from "react";
import {biographyDE, biographyEN, biographyFR} from "../../texts/biography";
import FixedTopBar from "../common/fixedTopBar";
import MediaBar from "../mediabar/mediabar";

class Biography extends React.Component{
    constructor(props){
        super(props);
    }

    state = {
        content: ""
    }
    async componentDidMount() {
        switch (this.props.lang) {
            case "de":
                await this.setState({content: biographyDE()});
                break;
            case "en":
                await this.setState({content: biographyEN()});
                break;
            case "fr":
                await this.setState({content: biographyFR()});
                break;
            default:
                await this.setState({content: <h3>Error!</h3>});
                console.log(this.props.lang);
        }
    }
    render() {
        return(
            <div>
                <MediaBar />
                <FixedTopBar lang={this.props.lang} />
                <p>_</p>
                <div className={"content"}>
                    {this.state.content}
                </div>

            </div>
        );
    }
}

export default Biography;
