import React from "react";
import "font-awesome/css/font-awesome.min.css";
import "./mediabar.css";

class MediaBar extends React.Component{

    render() {
        return (
            <div className={"icon-bar"}>
                <a href="https://www.facebook.com/atelierfuermalerei" target={"_blank"} className="facebook"><i className="fa fa-facebook"></i></a>
                <a href="https://www.instagram.com/atelier_fuer_malerei" target={"_blank"} className="instagram"><i className="fa fa-instagram"></i></a>
            </div>
        );
    }

}

export default MediaBar;
