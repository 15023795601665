import React from "react";
import Header from "./header";
import NavBar from "./navBar";
import "../../stylesheets/topBar.css";
import Slider from "./slider";

class FixedTopBar extends React.Component{
    constructor(props) {
        super(props);
    }


    render() {
        return(
            <React-Fragment>
                <div className={"topBar"}>
                    <Header />
                    <NavBar lang={this.props.lang} />
                </div>
                <Slider lang={this.props.lang} />
            </React-Fragment>
        );
    }
}

export default FixedTopBar;