import React from "react";
import Header from "../common/header";
import "../../stylesheets/languageSelect.css";
import "../../stylesheets/common.css";
import {Link} from "react-router-dom";
import imgDe from "../../images/lang01.png";
import imgEn from "../../images/lang02.png";
import imgFr from "../../images/lang03.png";

class LanguageSelect extends React.Component{
    render() {
        return(<React-Fragment>
            <Header />
            <div className="spacertopsmall">
                <h3 style={{color: "#54203b"}} align={"center"}>Melanie Foster</h3><br />
                <div className="row" style={{margin: 0}}>
                    <div className="col-md-3"></div>
                    <div className="col-md-6 languagecontainer">
                        <p className={"noticeMobile"} align="center" style={{color: "#fff", marginTop: "30px"}}>Bitte wählen Sie Ihre Sprache / Please select your language</p>
                        <div className={"spacerDesktop"} style={{height: "90px"}}></div>
                        <div className={"row"} style={{margin: 0}}>
                            <div className={"col-md-4"}><p align={"center"}><Link to={"/willkommen"}><img className={"lanImg"} alt={"GERMAN"} src={imgDe} /></Link></p></div>
                            <div className={"col-md-4"}><p align={"center"}><Link to={"/welcome"}><img className={"lanImg"} alt={"ENGLISH"} src={imgEn} /></Link></p></div>
                            <div className={"col-md-4"}><p align={"center"}><Link to={"/bonjour"}><img className={"lanImg"} alt={"FRENCH"} src={imgFr} /></Link></p></div>
                        </div>
                        <p className={"noticeDesktop"} align="center" style={{color: "#fff", marginBottom: "90px"}}>Bitte wählen Sie Ihre Sprache / Please select your language</p>
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>
        </React-Fragment>);
    }
}
export default LanguageSelect;